<template>
  <vue-modal @close="$emit('close')" ref="modal" size="sm">
    <template #title>Galería</template>
    <template #body>
      <FormText v-model="form.nombre" icon="fa-solid fa-tag" label="Nombre" :rules="{ required: true }"
        :disabled="(item && item.isShowing)" />
      <div class="main_gallery m-4">
        <PageFunctions :actual-page="items.current_page" :last-page="items.last_page" @changing_page="pageChanged"
          @changing_showing="showChanged" />
        <div class="row gap-2 justify-content-center">
          <div style="height: 150px; width: 150px" v-for="i of items.data" :key="i.id">
            <div class="img-container" :style="`background-image: url('${['mp4', 'mkv', 'mov', 'mwv', 'avi', 'webm', 'flv', 'qt', 'quicktime'].some(x => i.type.includes(x))
              ? require('@/assets/img/video.png')
              : i.type.includes('video')
                ? require('@/assets/img/video.png')
                : i.type.includes('pdf')
                  ? require('@/assets/img/pdf.png')
                  : i.type.includes('csv')
                    ? require('@/assets/img/csv.png')
                    : i.type.includes('xlsx')
                      ? require('@/assets/img/csv.png')
                      : i.type.includes('application/vnd.ms-excel')
                        ? require('@/assets/img/csv.png')
                        : i.type.includes('application/vnd.openxmlformats-officedocument.wordprocessingml.document')
                          ? require('@/assets/img/word.png')
                          : i.url}')`">
              <div class="img-buttons">
                <p>{{ i.name.slice(0, 10) + `...` }}</p>
                <div>
                  <button v-if="(item && item.isShowing === false) || (!item)" class="btn btn-outline-danger btn-rounded"
                    @click="deleteItem(i)">
                    <i class="fa-solid fa-trash"></i>
                  </button>
                  <a :href="i.url" target="_blank">
                    <button class="btn btn-outline-secondary btn-rounded">
                      <i class="fa-solid fa-folder-open"></i>
                    </button>
                  </a>
                  <button v-if="!i.type.includes('pdf') && !i.type.includes('csv')"
                    class="btn btn-outline-secondary btn-rounded"
                    @click="onZoom(i.type.includes('video') ? 'video' : 'img', i)">
                    <i class="fa-solid fa-magnifying-glass"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div v-if="(item && item.isShowing === false) || (!item)" class="d-flex m-3 btn btn-outline-primary"
            style="cursor: pointer; width: 180px; height: 80px;">
            <FormUploader @onChange="onFileSelected">
              <div class="img-sel-button">
                <i class="fa-solid fa-file-arrow-up"></i>
                <small> Click para subir archivo</small>
              </div>
            </FormUploader>
          </div>
        </div>
      </div>
      <div v-if="zoom" class="position-fixed zoom-items" @click="onZoom('close', $event)">
        <img class="position-absolute zoom-items-img" v-if="img_zoom" :src="img_zoom" alt="">
        <video id="video-zoom" class="position-absolute zoom-items-video" v-if="video_zoom" :src="video_zoom"
          controls></video>
      </div>
    </template>
    <template #footer>
      <button v-if="(item && item.isShowing === false) || (!item)" class="btn btn-primary"
        @click="saveData">Guardar</button>
    </template>
  </vue-modal>
</template>
<script>
import PageFunctions from "@/components/Tables/PageFunctions";
import FormUploader from "@/components/Form/FormUploader";
import FormText from "../../../components/Form/FormText.vue";

export default {
  components: {
    PageFunctions,
    FormUploader,
    FormText,
  },
  props: {
    item: {
      type: Object,
      default: null,
    },
  },
  data: () => ({
    form: {},
    filters: {
      per_page: 10,
      page: 1,
    },
    img_zoom: null,
    video_zoom: null,
    zoom: false,
  }),
  computed: {
    items() {
      return this.$store.getters.files;
    },
  },
  async mounted() {
    this.getItems();
    if (this.item) {
      this.form = { ...this.item };
    }
  },
  methods: {
    onZoom(type, item) {
      if (type === 'img') {
        return this.img_zoom = item.url, this.zoom = true
      }
      if (type === 'video') {
        return this.video_zoom = item.url, this.zoom = true
      }
      if (type === 'close') {
        if (item.target.id !== 'video-zoom') { return this.img_zoom = null, this.video_zoom = null, this.zoom = false }
      }
    },
    close() {
      this.$refs.modal.close();
    },
    pageChanged(val) {
      this.filters.page = val;
      this.getItems();
    },
    showChanged(val) {
      this.filters.page = 1;
      this.filters.per_page = val;
      this.getItems();
    },
    async getItems() {
      await this.$store.commit("CLEAR_FILES");
      if (this.form.id) {
        this.$store.dispatch("getFiles", { id_galeria: this.form.id, params: this.filters });
      }
    },
    onFileSelected({ file, evt }) {
      if (!this.form.nombre) {
        this.$toast.error("Debe ingresar un nombre para la galeria");
        evt.target.value = "";
        this.getItems();
        return;
      }
      const fd = new FormData();
      fd.append("file", file);
      if (this.form.id) {
        fd.append("id_galeria", this.form.id);
        this.$store.dispatch("createFile", fd);
        return;
      }
      // No hay galeria seleccionada
      const form = {
        nombre: this.form.nombre,
      };
      this.$store.dispatch("createGaleria", form).then(({ data }) => {
        this.form.id = data.galeria.id
        fd.append("id_galeria", data.galeria.id);
        this.$store.dispatch("createFile", fd);
      });
    },
    deleteItem(item) {
      this.$alert
        .fireConfirm("Borrar Imagen", "Esta seguro que desea continuar?")
        .then(() => {
          this.$store.dispatch("deleteFile", { id: item.id, id_galeria: this.form.id });
        });
    },
    saveData() {
      const id = this.form.id
      const form = {
        nombre: this.form.nombre,
      }
      if (this.form.id) {
        this.$store
          .dispatch("updateGaleria", {
            id,
            form,
          })
          .then(() => {
            this.close();
          });
      } else {
        this.$store.dispatch("createGaleria", form).then(() => {
          this.close();
        });
      }
      this.$emit('saved')
    },
  },
};
</script>
<style lang="scss" scoped>
.img-buttons {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &>p {
    color: white;
    margin-bottom: 10px;
  }

  &>div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
  }
}

.zoom-items {
  z-index: 5;
  inset: 0;
  background-color: rgba(0, 0, 0, 0.9);
  display: grid;
  place-items: center;
}

.zoom-items-img {
  max-height: 70vh;
  max-width: 70vw;
}

.zoom-items-video {
  max-height: 50vh;
  max-width: 50vw;
}
</style>